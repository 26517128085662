import React from 'react'
import PropTypes from 'prop-types'
import { propType } from 'graphql-anywhere'
import { map, pathOr } from 'ramda'

import FooterLink from './navigation-footer-link'

import NAV_ITEM_FRAGMENT from '../../../graphql/fragments/nav-item.gql'
import NAV_SECTION_FRAGMENT from '../../../graphql/fragments/nav-section.gql'
import styles from './navigation-footer-kids.css'

const NavigationFooterKids = ({
  bottomLinks,
  messageSection
}) => {
  const legalMessage = pathOr('', ['children', 0, 'content'], messageSection)
  const renderBottomLinks = map(
    link => (
      <FooterLink
        key={link.name + link.path + link.target}
        linkStyle={styles.bottomLink}
        text={link.name}
        to={link.path || link.target}
      />
    )
  )

  return (
    <div className={styles.wrapper}>
      <div className={styles.legalMessage}>{legalMessage}</div>
      <div className={styles.bottomLinkWrapper}>
        {renderBottomLinks(bottomLinks)}
      </div>
    </div>
  )
}

NavigationFooterKids.propTypes = {
  bottomLinks: PropTypes.arrayOf(propType(NAV_ITEM_FRAGMENT)).isRequired,
  messageSection: propType(NAV_SECTION_FRAGMENT).isRequired
}

export default NavigationFooterKids
