import PropTypes from 'prop-types'
import React from 'react'
import NavLinkWithQuery from '../../components/shared/nav-link-with-query'
import { isFullyQualifiedUrl } from '../../lib/location'

const NavigationFooterLink = ({
  linkStyle,
  text,
  to
}) => {
  if (!to) {
    return <span className={linkStyle}>{text}</span>
  }

  return isFullyQualifiedUrl(to)
    ? <a className={linkStyle} href={to}>{text}</a>
    : <NavLinkWithQuery className={linkStyle} to={to}>{text}</NavLinkWithQuery>
}

NavigationFooterLink.propTypes = {
  linkStyle: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
}

NavigationFooterLink.defaultProps = {
  to: null
}

export default NavigationFooterLink
