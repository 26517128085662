import { map, pathOr } from 'ramda'
import PropTypes from 'prop-types'
import { propType } from 'graphql-anywhere'
import React from 'react'
import ReactSVG from 'react-svg'
import classnames from 'classnames'

import FooterLink from './navigation-footer-link'

import NAV_ITEM_FRAGMENT from '../../../graphql/fragments/nav-item.gql'
import NAV_SECTION_FRAGMENT from '../../../graphql/fragments/nav-section.gql'

import styles from './navigation-footer.css'
import facebookIcon from '../../../images/social/facebook.svg'
import twitterIcon from '../../../images/social/twitter.svg'
import instagramIcon from '../../../images/social/instagram.svg'

const SvgSocialMediaWrapper = ({ path, className }) => (
  <ReactSVG
    src={path}
    beforeInjection={(svg) => {
      svg.setAttribute('class', classnames(styles.socialImage, className))
    }}
  />
)

SvgSocialMediaWrapper.propTypes = {
  path: PropTypes.string.isRequired,
  className: PropTypes.string
}

SvgSocialMediaWrapper.defaultProps = {
  className: ''
}

// NB: The footer doesn't support menus. Don't nest nav items!
const NavigationFooter = ({
  bottomLinks,
  messageSection,
  topLinks,
  facebookUsername,
  instagramUsername,
  twitterUsername
}) => {
  const legalMessage = pathOr('', ['children', 0, 'content'], messageSection)
  const renderBottomLinks = map(
    link => (
      <FooterLink
        key={link.name + link.path + link.target}
        linkStyle={styles.bottomLink}
        text={link.name}
        to={link.path || link.target}
      />
    )
  )

  const isWhiteTheme = false

  return (
    <div
      data-lbx-e2e="footer"
      className={classnames(styles.navFooterWrapper, {
        [styles.whiteTheme]: isWhiteTheme
      })}
    >
      <div className={styles.topLinkAndSocialMediaWrapper}>
        <div className={styles.topLinksWrapper}>
          {
            map(link => (
              <FooterLink
                key={link.name + link.path + link.target}
                linkStyle={styles.topLink}
                text={link.name}
                to={link.path || link.target}
              />
            ), topLinks)
          }
        </div>
        <div className={styles.socialButtonWrapper}>
          <a
            className={styles.socialButton}
            href={`https://www.facebook.com/${facebookUsername}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <SvgSocialMediaWrapper path={facebookIcon} className={styles.facebookIcon} />
          </a>
          <a
            className={styles.socialButton}
            href={`https://twitter.com/${twitterUsername}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <SvgSocialMediaWrapper path={twitterIcon} className={styles.twitterIcon} />
          </a>
          <a
            className={styles.socialButton}
            href={`https://instagram.com/${instagramUsername}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <SvgSocialMediaWrapper path={instagramIcon} className={styles.instagramIcon} />
          </a>
        </div>
      </div>
      <div className={styles.legalMessage}>{legalMessage}</div>
      <div className={styles.bottomLinkWrapper}>
        {renderBottomLinks(bottomLinks)}
      </div>
    </div>
  )
}

NavigationFooter.propTypes = {
  facebookUsername: PropTypes.string.isRequired,
  instagramUsername: PropTypes.string.isRequired,
  twitterUsername: PropTypes.string.isRequired,
  bottomLinks: PropTypes.arrayOf(propType(NAV_ITEM_FRAGMENT)).isRequired,
  messageSection: propType(NAV_SECTION_FRAGMENT).isRequired,
  topLinks: PropTypes.arrayOf(propType(NAV_ITEM_FRAGMENT)).isRequired
}

export default NavigationFooter
