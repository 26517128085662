import {
  compose,
  mapProps,
  branch,
  renderComponent
} from 'recompose'
import { concat } from 'ramda'
import { connect } from 'react-redux'
import { graphql } from 'react-apollo'

import { ACCOUNT_SCREENS, MODALS } from '../../constants'
import {
  facebookSelector,
  instagramSelector,
  twitterSelector
} from '../../selectors/config'
import {
  findLinks,
  findSection
} from '../../lib/utils'
import { getModalLocation } from '../../lib/modal'
import NAV_FOOTER_QUERY from '../../../graphql/queries/navigation-footer.gql'
import NavigationFooter from '../../layouts/shared-navigation/navigation-footer'
import NavigationFooterKids from '../../layouts/shared-navigation/navigation-footer-kids'
import withConfig from '../../hoc/with-config'
import withIsAuthenticated from '../../hoc/is-authenticated'

const mapStateToProps = (state) => {
  // TODO consider proper way to define theme/context
  return {
    isKid: state.theme === 'LIGHTBOX_KIDS',
    theme: state.theme
  }
}

const placeholderMessageSection = {
  __typename: 'NavContent',
  children: [{
    __typename: 'NavContent',
    content: 'Marketing and Legal Copy',
    name: 'footer.message'
  }],
  name: 'footer.message'
}

const createStaticNavItems = (isAuthenticated) => {
  if (isAuthenticated) {
    return [
      {
        isRental: false,
        isDiscoverNav: false,
        name: 'My Account',
        path: ACCOUNT_SCREENS.MY_ACCOUNT,
        target: null,
        type: 'SCREEN_ID',
        __typename: 'NAV_ITEM'
      }
    ]
  }

  return [
    {
      isRental: false,
      isDiscoverNav: false,
      name: 'Sign Up',
      path: '/signup',
      target: null,
      type: 'SCREEN_ID',
      __typename: 'NAV_ITEM'
    },
    {
      isRental: false,
      isDiscoverNav: false,
      name: 'Log In',
      path: getModalLocation(location, MODALS.qsParams.login),
      target: null,
      type: 'SCREEN_ID',
      __typename: 'NAV_ITEM'
    }
  ]
}

export default compose(
  withIsAuthenticated,
  connect(
    mapStateToProps
  ),
  withConfig,
  graphql(NAV_FOOTER_QUERY, {
    name: 'navFooterQuery',
    options: {
      fetchPolicy: 'cache-only'
    },
    props: ({
      ownProps: { isAuthenticated, featureflags, ...ownProps },
      navFooterQuery: { navFooter = [] }
    }) => {
      const staticNavItems = createStaticNavItems(isAuthenticated)
      const dynamicTopLinks = findLinks('footer.top_links', navFooter)
      const messageSection = findSection('footer.message', navFooter) || placeholderMessageSection
      const bottomLinks = findLinks('footer.bottom_links', navFooter)

      return {
        ...ownProps,
        bottomLinks,
        messageSection,
        topLinks: concat(dynamicTopLinks, staticNavItems)
      }
    }
  }),
  mapProps(({
    topLinks,
    bottomLinks,
    messageSection,
    appConfig,
    isAuthenticated,
    ...restOfProps
  }) => {
    return {
      ...restOfProps,
      topLinks,
      isAuthenticated,
      messageSection,
      bottomLinks,
      facebookUsername: facebookSelector(appConfig),
      instagramUsername: instagramSelector(appConfig),
      twitterUsername: twitterSelector(appConfig)
    }
  }),
  branch(
    ({ isKid }) => isKid,
    renderComponent(NavigationFooterKids),
    renderComponent(NavigationFooter)
  )
)()
